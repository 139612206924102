<template>
    <v-menu
      attach
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
      :disabled="disbaled"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          outlined dense
          :value="value"
          :label="label"
          :disabled="disbaled"
          prepend-icon="mdi-calendar"
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker locale="es-SV" :value="parseDate(value)" @change="onChange" no-title scrollable @input="menu = false"  :disabled="disbaled">

      </v-date-picker>
    </v-menu>
</template>

<script>
export default {
  name: "DatePicker",
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10)
    }, 
    disbaled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      menu: false,
    };
  },
  methods: {
    onChange(event) {
      const newDate = this.formatDate(event)
      this.$emit('change', newDate)
      this.$emit('update', newDate)
    },
    formatDate(date) {
      if (!date) return null;
      
      const [year, month, day] = date.split("-");
      return this.moment(`${month}/${day}/${year}`).format('DD/MM/YYYY');
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
};
</script>

<style scoped>
</style>